import Home from './Home';
import Home02 from './Home02';
import Home03 from './Home03';
import Home01 from './Home01';
import Blog from './Blog';
import BlogSingle from './BlogSingle';

const routes = [
    { path: "/", component: Home},
    { path: "/home-02", component: Home02},
    { path: "/home-03", component: Home03},
    { path: "/home-01", component: Home01},
    { path: "/blog", component: Blog},
    { path: "/blog-single", component: BlogSingle},
]

export default routes;