import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <div className="tf-modal-content justify-content-end"> 
                <footer id="footer" className="footer footer-s4">
                    <div className="container">
                        <div id="footer-widget" className="footer-widget-s3 border-bottom-s2 position-relative">
                            <h3 className="widget-title larger lt-sp06 text-white text-center">Let’s try me!</h3>
                            <div className="site-list site-list-center text-center">
                            <a href="https://github.com/Emmakex" target="_blank" rel="noopener noreferrer" class="bg-s1"> <i class="fa fa-github" aria-hidden="true"></i></a>
                            <a href="https://www.linkedin.com/in/eduardoyauriluna/" target="_blank" rel="noopener noreferrer" class="bg-s2"> <i class="fa fa-linkedin" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                    <div id="bottom" className="bottom-s3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="copyright lt-sp02">
                                        © Eduardo Yauri, 2024 All rights reserved. <a href="emmake.com/" target="_blank" rel="noopener noreferrer">By Emmake INC</a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* <ul className="widget-nav-menu color-s2">
                                        <li><Link to="#home">Home</Link></li>
                                        <li><Link to="#portfolio">Portfolio</Link></li>
                                        <li><Link to="#about">About</Link></li>
                                        <li><Link to="#services">Services</Link></li>
                                        <li><Link to="#resume">CV</Link></li>
                                    </ul> */}
                                </div>
                                <div className="col-lg-2">
                                    <div className="socials-list color-s2">
                                    <a href="https://github.com/Emmakex" target="_blank" rel="noopener noreferrer" class="bg-s1"> <i class="fa fa-github" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/in/eduardoyauriluna/" target="_blank" rel="noopener noreferrer" class="bg-s2"> <i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
