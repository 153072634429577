import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class TopEducation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contenttop : [
                {
                    id: 1,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.3s',
                    year: 'Senior SEO & SEM Specialist ',
                    title: '7EXPERTS',
                    text: 'In charge of web positioning (SEO) in Spain and France, in charge of campaigns in Google Ads Spain and France, Google Analytics.'
                },
                {
                    id: 2,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.5s',
                    year: 'Chief Executive Officer',
                    title: 'EMMAKE INC',
                    text: 'Digital Transformation Projects, Development and Consulting in AI Technologies.'
                },
                {
                    id: 3,
                    classname: 'experience-box t3',
                    datawow: '0.7s',
                    year: 'Digital Marketing Manager',
                    title: 'RIDEON',
                    text: 'Digital Transformation: International and Multi-language web positioning (SEO), Social Media, campaigns in Google Ads, Facebook Ads and Google Analytics.'
                }
            ],
            contentbot : [
                {
                    id: 1,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '0.8s',
                    year: 'Digital Marketing Manager',
                    title: 'VANTO MACHINES',
                    office: '',
                    text: 'International and Multi-language web positioning (SEO), Social Media, campaigns in Google Ads, Facebook Ads and Google Analytics.'
                },
                {
                    id: 2,
                    classname: 'experience-box experience-box-line t3',
                    datawow: '1s',
                    year: 'Digital Marketing Manager',
                    title: 'IDENCITY',
                    office: '',
                    text: 'Web positioning (SEO), Social Media, campaigns in Adwords, Facebook Ads and Google Analytics.'
                },
                {
                    id: 3,
                    classname: 'experience-box t3',
                    datawow: '1.2s',
                    year: 'SEO Consultant',
                    title: 'TRAVEO',
                    office: '',
                    text: 'Webmaster (Development and Programming) and National and International Web Positioning (SEO).'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="animate-element delay5 fadeInUp">
                <div className="padding-bottom">
                    <div className="row">
                        {
                            this.state.contenttop.map(data => (
                                <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                    <div className={data.classname}>
                                        <div className="box-inner">
                                        <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                        <div className="exp-wrap">
                                            <div className="s-info color-d11 mg-b11">{data.title}</div>
                                            <p className="color-d13">
                                            {data.text}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="row">
                    {
                        this.state.contentbot.map(data => (
                            <div className="col-lg-4 col-12 last-col animate-element wow delay5 fadeIn" data-wow-delay={data.datawow} key={data.id}>
                                <div className={data.classname}>
                                    <div className="box-inner">
                                    <h3 className="f-info bg-s2 color-d6">{data.year}</h3>
                                    <div className="exp-wrap">
                                        <div className="s-info color-d11 mg-b11">{data.title} <Link to="#">{data.office}</Link></div>
                                        <p className="color-d13">
                                            {data.text} 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default TopEducation;
